import React, { useState } from "react";

const calculateDownload = () => {
  return 0;
};

const box = props => {
  return (
    <div className={props.className} style={{ margin: '20px 0px' }}>
      <div
        className="card"
        style={{
          background: "#FF90FF",
          color: "#FFFFFF",
          textAlign: "left",
          fontWeight: "bold"
        }}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-sm-3">
              <img src={props.img} style={{ width: 18 }} />
            </div>
            <div className="col-12 col-sm-9" style={{ paddingRight: 0 ,fontSize:12}}>{props.name}</div>
          </div>

          <div className="row">
            <div className="col-12">
              <h3 style={{ fontWeight: "bold" }}>{props.value}</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-12">{props.unit}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default box;
