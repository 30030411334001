import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import {createStore} from 'redux';
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import registerServiceWorker from "./registerServiceWorker";
import reducer from './store/reducer';
import {Provider} from 'react-redux';
const history = createBrowserHistory();

const store = createStore(reducer);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history} >
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
