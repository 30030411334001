import React, { Component } from "react";

import BoxFurtherDiag from '../../Components/BoxFurtherDiagnostics';
import InputTabs from "../../Components/InputTabs";
import { Link } from 'react-router-dom';
import SpeedTestDetails from "./DetailsComponent";
import completeImage from '../../Assets/checked.png';
import downloadIcon from '../../Assets/downloadIcon.png';
import pingIcon from '../../Assets/checked.png';
import query from 'query-string';
import uploadIcon from '../../Assets/uploadIcon.png';
import { withRouter } from "react-router";

class InitiateFurtherDiagnostics extends Component {
  constructor(props) {

    super(props)
    const OBJ = query.parse(this.props.location.search)
   

    const pingState_array = OBJ.ping.toString().split(',');
    const downloadState_array = OBJ.download.toString().split(',');
    const uploadState_array = OBJ.upload.toString().split(',');
    
    if (pingState_array.length > 1)
    {
      //window.alert("String Splitted");
    this.state = {
      box: [
        { name: "PING", img: pingIcon, value: pingState_array[0], unit: "Ms" },
        { name: "DOWNLOAD", img: downloadIcon, value: downloadState_array[0], unit: "Mbps" },
        { name: "UPLOAD", img: uploadIcon, value: uploadState_array[0], unit: "Mbps" }
      ],
      start: OBJ.start.toString().split(',')[0],
      end: OBJ.end.toString().split(',')[0],
      bytes: OBJ.bytes.toString().split(',')[0],
      startUpTime: OBJ.startUpTime.toString().split(',')[0],
      endUpTime: OBJ.endUpTime.toString().split(',')[0],
      uploadSize: OBJ.uploadSize.toString().split(',')[0]
    }
    //console.log()
    }
    else
    {
      //window.alert("String Not Splitted");
      this.state = {
        box: [
          { name: "PING", img: pingIcon, value: OBJ.ping, unit: "Ms" },
          { name: "DOWNLOAD", img: downloadIcon, value: OBJ.download, unit: "Mbps" },
          { name: "UPLOAD", img: uploadIcon, value: OBJ.upload, unit: "Mbps" }
        ],
        start: OBJ.start,
        end: OBJ.end,
        bytes: OBJ.bytes,
        startUpTime: OBJ.startUpTime,
        endUpTime: OBJ.endUpTime,
        uploadSize: OBJ.uploadSize
      }
    }
  }
 
  render() {
    return (

      <div className="row">
        <div className="col-12 col-md-4">
          <SpeedTestDetails ping={this.state.box[0].value} download={this.state.box[1].value} upload={this.state.box[2].value}/>

        </div>
        <div className="col-12 col-md-8">
          <div className="card space-for-header"style={{minHeight:649}}>
            <div className="card-body">
              <h1
                style={{ color: '#3C0E92', fontWeight: 'bold', display: 'inline-block', fontSize: 15, marginTop: 12 }}>
                Additional Diagnostic</h1>
              <Link to={`/details${this.props.location.search}`}
                style={{ display: 'inline-block', border: 'none', backgroundColor: 'none !important', fontSize: 10, float: 'right', marginRight: 20, marginTop: 10, color: 'maroon' }}>
                [CLOSE]
              </Link>
              <p></p>
              <p/>
              <h6 style={{ color: '#3C0E92', fontSize: 12, paddingBottom: 10}}>If you're a <span
                style={{ fontWeight: 'bold' }}>BT Broadband</span> customer and not getting the expected speed test result, you can run Additional Diagnostics by selecting one of the fields below. Your current internet speed will be compared with the speed defined for your connection.</h6>
              <InputTabs ping={this.state.box[0].value} download={this.state.box[1].value} upload={this.state.box[2].value}
                bytes={this.state.bytes} start={this.state.start} end={this.state.end} startUpTime={this.state.startUpTime}
                endUpTime={this.state.endUpTime} uploadSize={this.state.uploadSize} />
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default withRouter(InitiateFurtherDiagnostics);