import React, { Component } from "react";

import DCarousel from "../../Components/DCarousel";
import { Link } from "react-router-dom";
import Para from '../../Components/CenterPara'

class Home extends Component {
  render() {
    return (
        <div className="row" style={{marginTop:60,marginLeft:40}}>
            <div className="col-md-6 col-sm-12 " style={{height:400}} >
                  <Para
                      fontSize= '28px'
                      fontWeight= {700}
                  >
                    BT Wholesale
                  </Para>
                  <Para
                      color= '#FF90FF'
                      fontSize= '28px'
                      fontWeight= {700}
                  >
                    BroadBand Performance Test
                  </Para>
                  <Para>
                    This broadband Performance Tester allows you to test the performance of <br/>
                    your broadband connection. It can also be used in conjunction with other<br/>
                    broadband service.
                  </Para>
                <Link to="/details">
                  <button className="testButton">GO</button>
                </Link>
            </div>
            <div classname="col-md-6 col-sm-12" style={{
            width: 500,opacity: 0.9,
            boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)"}}>
            <DCarousel />

            </div>
          </div>
    );
  }
}

export default Home;
