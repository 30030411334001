import "./index.css";

import * as starttest from "../../JS/starttest";
import * as fttptest from "../../JS/fttptest";
import { connect } from 'react-redux';
import {compose } from 'redux';
import React, { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import query from "query-string";
import { withRouter } from "react-router-dom";
import xmlData from "./initialise.js";
import boxFurtherDiag from "../BoxFurtherDiagnostics";


var postCodeServeletArray=[];
var url="";
var destination=process.env.REACT_APP_DESTINATION;
var flag = 0;
const InputTabs = props => {
  const [key, setKey] = useState("BBEU");
  var png = props.ping;
  var down = props.download;
  var up = props.upload;
  var st = props.start;
  var en = props.end;
  var byt = props.bytes;
  var startUp = props.startUpTime;
  var endUp = props.endUpTime;
  var upSize = props.uploadSize;
var postcode="";
var address="";
  async function handlePostcodeSubmit (){
    
    postCodeServeletArray=[];
     postcode =document.getElementById("postcode").value;
     address= document.getElementById("address").value;
     var download = document.getElementById("download").value * 1000;
    var upload = document.getElementById("upload").value * 1000;
    var ping = document.getElementById("ping").value;
    var start = document.getElementById("start").value;
    var end = document.getElementById("end").value;
    var startUpTime = document.getElementById("startUpTime").value;
    var endUpTime = document.getElementById("endUpTime").value;
    var uploadSize = document.getElementById("uploadSize").value;
    var bytes = document.getElementById("bytes").value;

    postCodeServeletArray=fttptest.startFTTPTest(upload,download,start,end,startUpTime,endUpTime,uploadSize,bytes,postcode,address);
//console.log(postCodeServeletArray)
    if(postCodeServeletArray[0]!="Error"){
     
    if(postCodeServeletArray.length==10){

      
      url="/getAddress.do?postalcode="+postcode+"&buildingnumber="+address+"&up="+up+"&down="+down+"&totalbytes="+byt+"&start="+st+"&end="+en+"&startUpTime="+startUp+"&endUpTime="+endUp+"&uploadSize="+upSize
   
    }
    else{
      url="/getAddress.do?postalcode="+postcode+"&up="+up+"&down="+down+"&totalbytes="+byt+"&start="+st+"&end="+en+"&startUpTime="+startUp+"&endUpTime="+endUp+"&uploadSize="+upSize
     
    }

        
    var xhr = new XMLHttpRequest();
   
    var response = "Error";

     xhr.onreadystatechange = function() {
       //window.alert("inside xhr ready state change")
      if (xhr.readyState == 4 && xhr.status == 200) {
        //window.alert("inside succesful response wiyth status 200")
       // window.alert(xhr.response)
        response = xhr.response;
        //console.log(response);
        response = xhr.response;
        var XMLParser = require("xml-js");
        var options = {
          ignoreComment: true,
          alwaysChildren: true,
          addParent: true,
          compact: false
        };
        var result = XMLParser.xml2json(response, options); // or convert.xml2json(xml, options)
        //console.log("result is--->", result);
        const obj = JSON.parse(result);
        //window.alert(obj)
        //console.log("print obj -->", obj.elements[0].elements[0].name + obj.elements[0].elements[0].elements[0].text);
        if( obj.elements[0].elements[0].name == "addressCount" &&
        obj.elements[0].elements[0].elements[0].text>0){
          props.onJSONChange(obj);
          props.history.push(
            `/runDiagnostics?postcode=${postcode}&ping=${png}&download=${down}&upload=${up}&bytes=${byt}&start=${st}&end=${en}&startUpTime=${startUp}&endUpTime=${endUp}&uploadSize=${upSize}&statuscode=success`
          );
        }
        else{
          props.onJSONChange(obj);
          props.history.push(
            `/runDiagnostics?postcode=${postcode}&ping=${png}&download=${down}&upload=${up}&bytes=${byt}&start=${st}&end=${en}&startUpTime=${startUp}&endUpTime=${endUp}&uploadSize=${upSize}&statuscode=success`
          );
        }

      
      }
      else if(xhr.status == 500 || xhr.status == 400){
        props.history.push(
          `/runDiagnostics?ping=${png}&download=${down}&upload=${up}&bytes=${byt}&start=${st}&end=${en}&startUpTime=${startUp}&endUpTime=${endUp}&uploadSize=${upSize}&statuscode=error`
        );

      }
    };

    xhr.open(
      "GET",
      url,
      false
    );
    //xhr.withCredentials = false;
    xhr.send();
    }
    else{
      props.history.go(0);
    }
  }
  async function handleTelePhoneSubmit() {
     var serveletArray = [];
    var broadbandId = document.getElementById("serviceId").value;
    var download = document.getElementById("download").value * 1000;
    var upload = document.getElementById("upload").value * 1000;
    var ping = document.getElementById("ping").value;
    var start = document.getElementById("start").value;
    var end = document.getElementById("end").value;
    var startUpTime = document.getElementById("startUpTime").value;
    var endUpTime = document.getElementById("endUpTime").value;
    var uploadSize = document.getElementById("uploadSize").value;
    var bytes = document.getElementById("bytes").value;
    var username = "";
    var sBBIP = document.getElementById("BBP").value;
console.log(Number(sBBIP))
console.log(broadbandId)
console.log(broadbandId,download,upload,ping,start,end,startUpTime,endUpTime,uploadSize,bytes,username,sBBIP)
    //window.alert("BACK TO REACT COMPONENT")
    serveletArray = starttest.startTest(
      upload,
      download,
      start,
      end,
      startUpTime,
      endUpTime,
      uploadSize,
      bytes,
      broadbandId,
      username,
      sBBIP
    );
    console.log(serveletArray)
    if(serveletArray[0]!="Error"){
    var xhr = new XMLHttpRequest();
   
    var response = "Error";

    xhr.onreadystatechange = function() {
      if (xhr.readyState == 4 && xhr.status == 200) {
        response = xhr.response;
        //response = xmlData;
        var XMLParser = require("xml-js");
        var options = {
          ignoreComment: true,
          alwaysChildren: true,
          addParent: true,
          compact: false
        };
        var result = XMLParser.xml2json(response, options); // or convert.xml2json(xml, options)
        //console.log("result is--->", result);
        const obj = JSON.parse(result);
        if (
          obj.elements[0].elements[0].name == "MessageType" &&
          obj.elements[0].elements[0].elements[0]
        ) {
          // window.alert("Inside checck condition 1  " +obj.elements[0].elements[0].name + obj.elements[0].elements[0].elements[0].text);
          if (obj.elements[0].elements[0].elements[0].text == "Success") {
            //window.alert("Inside second call forwarding to next")
            props.onJSONChange(obj);
            props.history.push(
              `/runDiagnostics?ping=${png}&download=${down}&upload=${up}&bytes=${byt}&start=${st}&end=${en}&startUpTime=${startUp}&endUpTime=${endUp}&uploadSize=${upSize}&statuscode=success`
            );
          } else {
            //window.alert("Inside second call forwarding to next with error code")
            props.onJSONChange(obj);
            props.history.push(
              `/runDiagnostics?ping=${png}&download=${down}&upload=${up}&bytes=${byt}&start=${st}&end=${en}&startUpTime=${startUp}&endUpTime=${endUp}&uploadSize=${upSize}&statuscode=success`
            );
          }
        }
      }
      else if(xhr.status == 500 || xhr.status == 400){
        props.history.push(
          `/runDiagnostics?ping=${png}&download=${down}&upload=${up}&bytes=${byt}&start=${st}&end=${en}&startUpTime=${startUp}&endUpTime=${endUp}&uploadSize=${upSize}&statuscode=error`
        );

      }
    };
console.log("/initialise_session.do?" +
"&BBIP=" +
sBBIP +
"&user=" +
serveletArray[1] +
"&domain=" +
serveletArray[2] +
"&OS=" +
serveletArray[3] +
"&BROWSER=" +
serveletArray[4] +
"&VERSION=" +
serveletArray[5] +
"&USRAGENT=" +
serveletArray[6] +
"&serviceId=" +
serveletArray[7] +
"&up=" +
serveletArray[8] +
"&down=" +
serveletArray[9] +
"&totalbytes=" +
serveletArray[10] +
"&start=" +
serveletArray[11] +
"&end=" +
serveletArray[12] +
"&startUpTime=" +
serveletArray[13] +
"&endUpTime=" +
serveletArray[14] +
"&uploadSize=" +
serveletArray[15])
    xhr.open(
      "GET",
      "/initialise_session.do?" +
        "&BBIP=" +
        sBBIP +
        "&user=" +
        serveletArray[1] +
        "&domain=" +
        serveletArray[2] +
        "&OS=" +
        serveletArray[3] +
        "&BROWSER=" +
        serveletArray[4] +
        "&VERSION=" +
        serveletArray[5] +
        "&USRAGENT=" +
        serveletArray[6] +
        "&serviceId=" +
        "&up=" +
        serveletArray[8] +
        "&down=" +
        serveletArray[9] +
        "&totalbytes=" +
        serveletArray[10] +
        "&start=" +
        serveletArray[11] +
        "&end=" +
        serveletArray[12] +
        "&startUpTime=" +
        serveletArray[13] +
        "&endUpTime=" +
        serveletArray[14] +
        "&uploadSize=" +
        serveletArray[15],
      false
    );
    xhr.withCredentials = false;
    xhr.send();}
else if(serveletArray[0]="Error"){
  //window.alert("here")
  props.history.go(0);
}
  }
  async function handleAlidSubmit() {
    var serveletArray = [];
   var broadbandId = document.getElementById("serviceId").value;
   var download = document.getElementById("download").value * 1000;
   var upload = document.getElementById("upload").value * 1000;
   var ping = document.getElementById("ping").value;
   var start = document.getElementById("start").value;
   var end = document.getElementById("end").value;
   var startUpTime = document.getElementById("startUpTime").value;
   var endUpTime = document.getElementById("endUpTime").value;
   var uploadSize = document.getElementById("uploadSize").value;
   var bytes = document.getElementById("bytes").value;
   var username = "";
   var ALID = document.getElementById("ALID").value;
console.log(Number(ALID))
console.log(broadbandId)
console.log(broadbandId,download,upload,ping,start,end,startUpTime,endUpTime,uploadSize,bytes,username,ALID)
   //window.alert("BACK TO REACT COMPONENT")
   serveletArray = starttest.startTest(
     upload,
     download,
     start,
     end,
     startUpTime,
     endUpTime,
     uploadSize,
     bytes,
     broadbandId,
     username,
     ALID
   );
   console.log(serveletArray)
   if(serveletArray[0]!="Error"){
   var xhr = new XMLHttpRequest();
  
   var response = "Error";

   xhr.onreadystatechange = function() {
     if (xhr.readyState == 4 && xhr.status == 200) {
       response = xhr.response;
       //response = xmlData;
       var XMLParser = require("xml-js");
       var options = {
         ignoreComment: true,
         alwaysChildren: true,
         addParent: true,
         compact: false
       };
       var result = XMLParser.xml2json(response, options); // or convert.xml2json(xml, options)
       //console.log("result is--->", result);
       const obj = JSON.parse(result);
       if (
         obj.elements[0].elements[0].name == "MessageType" &&
         obj.elements[0].elements[0].elements[0]
       ) {
         // window.alert("Inside checck condition 1  " +obj.elements[0].elements[0].name + obj.elements[0].elements[0].elements[0].text);
         if (obj.elements[0].elements[0].elements[0].text == "Success") {
           //window.alert("Inside second call forwarding to next")
           props.onJSONChange(obj);
           props.history.push(
             `/runDiagnostics?ping=${png}&download=${down}&upload=${up}&bytes=${byt}&start=${st}&end=${en}&startUpTime=${startUp}&endUpTime=${endUp}&uploadSize=${upSize}&statuscode=success`
           );
         } else {
           //window.alert("Inside second call forwarding to next with error code")
           props.onJSONChange(obj);
           props.history.push(
             `/runDiagnostics?ping=${png}&download=${down}&upload=${up}&bytes=${byt}&start=${st}&end=${en}&startUpTime=${startUp}&endUpTime=${endUp}&uploadSize=${upSize}&statuscode=success`
           );
         }
       }
     }
     else if(xhr.status == 500 || xhr.status == 400){
       props.history.push(
         `/runDiagnostics?ping=${png}&download=${down}&upload=${up}&bytes=${byt}&start=${st}&end=${en}&startUpTime=${startUp}&endUpTime=${endUp}&uploadSize=${upSize}&statuscode=error`
       );

     }
   };
console.log("/initialise_session.do?" +
"&BBIP=" +
ALID +
"&user=" +
serveletArray[1] +
"&domain=" +
serveletArray[2] +
"&OS=" +
serveletArray[3] +
"&BROWSER=" +
serveletArray[4] +
"&VERSION=" +
serveletArray[5] +
"&USRAGENT=" +
serveletArray[6] +
"&serviceId=" +
serveletArray[7] +
"&up=" +
serveletArray[8] +
"&down=" +
serveletArray[9] +
"&totalbytes=" +
serveletArray[10] +
"&start=" +
serveletArray[11] +
"&end=" +
serveletArray[12] +
"&startUpTime=" +
serveletArray[13] +
"&endUpTime=" +
serveletArray[14] +
"&uploadSize=" +
serveletArray[15])
   xhr.open(
     "GET",
     "/initialise_session.do?" +
       "&BBIP=" +
       ALID +
       "&user=" +
       serveletArray[1] +
       "&domain=" +
       serveletArray[2] +
       "&OS=" +
       serveletArray[3] +
       "&BROWSER=" +
       serveletArray[4] +
       "&VERSION=" +
       serveletArray[5] +
       "&USRAGENT=" +
       serveletArray[6] +
       "&serviceId=" +
       "&up=" +
       serveletArray[8] +
       "&down=" +
       serveletArray[9] +
       "&totalbytes=" +
       serveletArray[10] +
       "&start=" +
       serveletArray[11] +
       "&end=" +
       serveletArray[12] +
       "&startUpTime=" +
       serveletArray[13] +
       "&endUpTime=" +
       serveletArray[14] +
       "&uploadSize=" +
       serveletArray[15],
     false
   );
   xhr.withCredentials = false;
   xhr.send();}
else if(serveletArray[0]="Error"){
 //window.alert("here")
 props.history.go(0);
}
 }
  async function handleSubmit() {
    var serveletArray = [];
    var broadbandId = document.getElementById("serviceId").value;
    var download = document.getElementById("download").value * 1000;
    var upload = document.getElementById("upload").value * 1000;
    var ping = document.getElementById("ping").value;
    var start = document.getElementById("start").value;
    var end = document.getElementById("end").value;
    var startUpTime = document.getElementById("startUpTime").value;
    var endUpTime = document.getElementById("endUpTime").value;
    var uploadSize = document.getElementById("uploadSize").value;
    var bytes = document.getElementById("bytes").value;
    var username = "";
    var sBBIP = "";
console.log(Number(sBBIP))
console.log(broadbandId)
console.log(broadbandId,download,upload,ping,start,end,startUpTime,endUpTime,uploadSize,bytes,username,sBBIP)
    //window.alert("BACK TO REACT COMPONENT")
    serveletArray = starttest.startTest(
      upload,
      download,
      start,
      end,
      startUpTime,
      endUpTime,
      uploadSize,
      bytes,
      broadbandId,
      username,
      sBBIP
    );
    console.log("bchjsakjkjaewhkjhwa----",serveletArray[7],broadbandId)
    console.log(serveletArray)
if(serveletArray[0]!="Error")
{
    var xhr = new XMLHttpRequest();

    var response = "Error";

    xhr.onreadystatechange = function() {
      //window.alert(" xhr.onreadystatechange ");
      if (xhr.readyState == 4 && xhr.status == 200) {
        response = xhr.response;
        //response = xmlData;
        var XMLParser = require("xml-js");
        var options = {
          ignoreComment: true,
          alwaysChildren: true,
          addParent: true,
          compact: false
        };
        var result = XMLParser.xml2json(response, options); // or convert.xml2json(xml, options)
        //console.log("result is--->", result);
        const obj = JSON.parse(result);
        if (
          obj.elements[0].elements[0].name == "MessageType" &&
          obj.elements[0].elements[0].elements[0]
        ) {
          // window.alert("Inside checck condition 1  " +obj.elements[0].elements[0].name + obj.elements[0].elements[0].elements[0].text);
          if (obj.elements[0].elements[0].elements[0].text == "Success") {
            //window.alert("Inside second call forwarding to next")
            props.onJSONChange(obj);
            props.history.push(
              `/runDiagnostics?ping=${png}&download=${down}&upload=${up}&bytes=${byt}&start=${st}&end=${en}&startUpTime=${startUp}&endUpTime=${endUp}&uploadSize=${upSize}&statuscode=success`
            );
          } else {
            //window.alert("Inside second call forwarding to next with error code")
            props.onJSONChange(obj);
            props.history.push(
              `/runDiagnostics?ping=${png}&download=${down}&upload=${up}&bytes=${byt}&start=${st}&end=${en}&startUpTime=${startUp}&endUpTime=${endUp}&uploadSize=${upSize}&statuscode=success`
            );
          }
        }
      }
      else if(xhr.status == 500 || xhr.status == 400){
        props.history.push(
          `/runDiagnostics?ping=${png}&download=${down}&upload=${up}&bytes=${byt}&start=${st}&end=${en}&startUpTime=${startUp}&endUpTime=${endUp}&uploadSize=${upSize}&statuscode=error`
        );

      }
    };
console.log("/initialise_session.do?" +
"&BBIP=" +
"&user=" +
serveletArray[1] +
"&domain=" +
serveletArray[2] +
"&OS=" +
serveletArray[3] +
"&BROWSER=" +
serveletArray[4] +
"&VERSION=" +
serveletArray[5] +
"&USRAGENT=" +
serveletArray[6] +
"&serviceId=" +
broadbandId +
"&up=" +
serveletArray[8] +
"&down=" +
serveletArray[9] +
"&totalbytes=" +
serveletArray[10] +
"&start=" +
serveletArray[11] +
"&end=" +
serveletArray[12] +
"&startUpTime=" +
serveletArray[13] +
"&endUpTime=" +
serveletArray[14] +
"&uploadSize=" +
serveletArray[15])
    xhr.open(
      "GET",
   "/initialise_session.do?" +
        "&BBIP=" +
        serveletArray[0] +
        "&user=" +
        serveletArray[1] +
        "&domain=" +
        serveletArray[2] +
        "&OS=" +
        serveletArray[3] +
        "&BROWSER=" +
        serveletArray[4] +
        "&VERSION=" +
        serveletArray[5] +
        "&USRAGENT=" +
        serveletArray[6] +
        "&serviceId=" +
        broadbandId +
        "&up=" +
        serveletArray[8] +
        "&down=" +
        serveletArray[9] +
        "&totalbytes=" +
        serveletArray[10] +
        "&start=" +
        serveletArray[11] +
        "&end=" +
        serveletArray[12] +
        "&startUpTime=" +
        serveletArray[13] +
        "&endUpTime=" +
        serveletArray[14] +
        "&uploadSize=" +
        serveletArray[15],
      false
    );
    xhr.withCredentials = false;
    xhr.send();
    //window.alert("after send")
}
else if(serveletArray[0]="Error" || serveletArray.length>16){
 // window.alert("here")
  props.history.go(0);
}
  }

  return (
    <div className="input">
      <Tabs
        style={{ marginLeft: -12 }}
        activeKey={key}
        onSelect={k => setKey(k)}
      >
        <Tab eventKey="BBEU" title="Broadband Service ID (BBEU)">
          <div className="bbeuForm">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBBEU">
                <br></br>
                <Form.Label style={{fontWeight:"bold"}}>Broadband Circuit Service ID </Form.Label>
                <Form.Control
                  placeholder="e.g. BBEUXXXXXX"
                  type="text"
                  name="serviceId"
                  id="serviceId"
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="download"
                  id="download"
                  value={down}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="upload"
                  id="upload"
                  value={up}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="ping"
                  id="ping"
                  value={png}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="start"
                  id="start"
                  value={st}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="end"
                  id="end"
                  value={en}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="bytes"
                  id="bytes"
                  value={byt}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="startUpTime"
                  id="startUpTime"
                  value={startUp}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="endUpTime"
                  id="endUpTime"
                  value={startUp}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="uploadSize"
                  id="uploadSize"
                  value={upSize}
                ></Form.Control>
              </Form.Group>
              <Form.Group><Form.Control
                  type="hidden"
                  name="download"
                  id="download"
                  value={down}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="upload"
                  id="upload"
                  value={up}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="ping"
                  id="ping"
                  value={png}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="start"
                  id="start"
                  value={st}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="end"
                  id="end"
                  value={en}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="bytes"
                  id="bytes"
                  value={byt}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="startUpTime"
                  id="startUpTime"
                  value={startUp}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="endUpTime"
                  id="endUpTime"
                  value={startUp}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="uploadSize"
                  id="uploadSize"
                  value={upSize}
                ></Form.Control>
                 <Form.Control
                  type="hidden"
                  name="BBIP"
                  id="BBIP"
                  value=""
                ></Form.Control><span style={{color:'#3c0e92', fontSize:14,fontWeight:"bold"}}>Please note that a Broadband Service ID can be used for all broadband services.<br/><br/>

                Access Line ID can be used for Single Order broadband services whilst Telephone Number can be used for traditional copper-based broadband services.
                
               <br/> <br/>If you’re not sure what broadband service you have or don’t know your Broadband Service ID or Access Line ID, please contact your broadband service provider.
               </span></Form.Group>
              <Button type="submit">Run Diagnostic</Button>
            </Form>
            <br/>
          </div>
        </Tab>
        <Tab eventKey="telno" title="Telephone Number (DN)">
          <div className="bbeuForm">
            <Form onSubmit={handleTelePhoneSubmit}>
              <Form.Group controlId="formBBEU">
                <br></br>
                <Form.Label style={{fontWeight:"bold"}}>Telephone number of the broadband circuit id</Form.Label>
                <Form.Control
                  placeholder="e.g. 0123456789"
                  type="text"
                  name="BBP"
                  id="BBP"
                ></Form.Control>
                  <Form.Control
                  type="hidden"
                  name="serviceID"
                  id="serviceID"
                  value=""
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="download"
                  id="download"
                  value={down}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="upload"
                  id="upload"
                  value={up}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="ping"
                  id="ping"
                  value={png}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="start"
                  id="start"
                  value={st}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="end"
                  id="end"
                  value={en}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="bytes"
                  id="bytes"
                  value={byt}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="startUpTime"
                  id="startUpTime"
                  value={startUp}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="endUpTime"
                  id="endUpTime"
                  value={startUp}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="uploadSize"
                  id="uploadSize"
                  value={upSize}
                ></Form.Control>
              </Form.Group>
              <Form.Group><Form.Control
                  type="hidden"
                  name="download"
                  id="download"
                  value={down}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="upload"
                  id="upload"
                  value={up}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="ping"
                  id="ping"
                  value={png}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="start"
                  id="start"
                  value={st}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="end"
                  id="end"
                  value={en}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="bytes"
                  id="bytes"
                  value={byt}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="startUpTime"
                  id="startUpTime"
                  value={startUp}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="endUpTime"
                  id="endUpTime"
                  value={startUp}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="uploadSize"
                  id="uploadSize"
                  value={upSize}
                 ></Form.Control>
                 <span style={{color:'#3c0e92', fontSize:14,fontWeight:"bold"}}>Please note that a Broadband Service ID can be used for all broadband services.<br/><br/>

Access Line ID can be used for Single Order broadband services whilst Telephone Number can be used for traditional copper-based broadband services.

<br/> <br/>If you’re not sure what broadband service you have or don’t know your Broadband Service ID or Access Line ID, please contact your broadband service provider.
</span></Form.Group> 
              <Button type="submit">Run Diagnostic</Button>
            </Form>
            <br/>
          </div>
        </Tab>
        <Tab eventKey="postcode" title="Access Line ID (ALID)">
        <div className="bbeuForm">
            <Form onSubmit={handleAlidSubmit}>
              <Form.Group controlId="formBBEU">
                <br></br>
                <Form.Label style={{fontWeight:"bold"}}>Access Line ID</Form.Label>
                <Form.Control
                  placeholder="e.g. C0000XXXXXXXX"
                  type="text"
                  name="ALID"
                  id="ALID"
                ></Form.Control>
                  <Form.Control
                  type="hidden"
                  name="serviceID"
                  id="serviceID"
                  value=""
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="download"
                  id="download"
                  value={down}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="upload"
                  id="upload"
                  value={up}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="ping"
                  id="ping"
                  value={png}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="start"
                  id="start"
                  value={st}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="end"
                  id="end"
                  value={en}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="bytes"
                  id="bytes"
                  value={byt}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="startUpTime"
                  id="startUpTime"
                  value={startUp}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="endUpTime"
                  id="endUpTime"
                  value={startUp}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="uploadSize"
                  id="uploadSize"
                  value={upSize}
                ></Form.Control>
              </Form.Group>
              <Form.Group><Form.Control
                  type="hidden"
                  name="download"
                  id="download"
                  value={down}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="upload"
                  id="upload"
                  value={up}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="ping"
                  id="ping"
                  value={png}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="start"
                  id="start"
                  value={st}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="end"
                  id="end"
                  value={en}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="bytes"
                  id="bytes"
                  value={byt}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="startUpTime"
                  id="startUpTime"
                  value={startUp}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="endUpTime"
                  id="endUpTime"
                  value={startUp}
                ></Form.Control>
                <Form.Control
                  type="hidden"
                  name="uploadSize"
                  id="uploadSize"
                  value={upSize}
                 ></Form.Control><span style={{color:'#3c0e92', fontSize:14,fontWeight:"bold"}}>Please note that a Broadband Service ID can be used for all broadband services.<br/><br/>

                 Access Line ID can be used for Single Order broadband services whilst Telephone Number can be used for traditional copper-based broadband services.
                 
                <br/> <br/>If you’re not sure what broadband service you have or don’t know your Broadband Service ID or Access Line ID, please contact your broadband service provider.
                </span></Form.Group> 
               
              <Button type="submit">Run Diagnostic</Button>
             
            </Form>
            <br/>
          </div>
        </Tab>
     
      </Tabs>
      
    </div>
  );
};

const mapStateToProps = state => {
  return {
    JSON:state.JSON
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onJSONChange: (JSON) => dispatch({type: 'JSON',val:JSON}),
      
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(InputTabs);
